import './Complaint.css'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from "react-icons/fa"
import { BsDot } from "react-icons/bs"

export default ()=>{
    const {t,i18n} = useTranslation()
    return(
        <>
        <div className='w-[100%] flex justify-center'>
            <div className='relative w-[100%] pc-3:w-[1366px] px-5 pc-1:px-10'>
                <div className='grid w-[100%] h-fit pt-24'>
                    <img src={require('../../assets/images/complaint_bg.png')} className='w-[100%] h-[298px] pc-1:h-[150px] object-bottom'/>
                </div>
                <div className='absolute block pc-1:flex justify-between items-center left-0 right-0 ml-auto mr-auto top-24 w-[80%] h-[150px]'>
                    {/* <div className='w-[5%]'>
                        <span src={require('../../assets/images/cs.png')} className='font-customExtraBold text-[23px] cursor-pointer'>
                            <FaArrowLeft />
                        </span>
                    </div> */}
                    <div className='w-[100%] pc-1:w-[40%] h-[150px] flex items-center'>
                        <span className='font-customExtraBold text-[33px] leading-tight'>
                        {t('complaint-header1')}<br></br>
                        {t('complaint-header2')}
                        </span>
                    </div>
                    <div className='hidden pc-1:block w-[25%]'/>
                    <img src={require('../../assets/images/cs.png')} className='w-[100%] pc-1:w-[30%] h-[148px] pc-1:h-[150px] object-contain'/>
                </div>
                <div className='w-[100%] h-fit py-2 px-0 pc-1:px-5'>{t('complaint-content1')}</div>

                <div className='w-[100%] h-fit px-0 pc-1:px-5'>
                    <span className='font-customExtraBold text-[21px] leading-tight'>
                    {t('complaint-content2')}
                    </span>
                    <div className='my-2'>
                        <span className='font-customExtraBold'>
                        {t('complaint-content3')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content4')}</div>
                        <span className='font-customExtraBold'>
                        {t('complaint-content5')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content6')}</div>
                        <div className='flex items-center justify-between w-[100%] h-fit border-[1px] border-gray-300 rounded-2xl px-2 py-2 mb-2'>
                            <div>
                                <div className='w-[100%] h-fit'>{t('complaint-content7')}</div>
                                <span className='font-customExtraBold'>
                                    0811 - 9990 518 (Chat Only)
                                </span>
                            </div>
                            <a href="https://api.whatsapp.com/send/?phone=628119990518 " data-v-9ba4bce6="" className='w-[175px] p-2 text-[14px] rounded-xl text-white bg-[#E77B30] font-customRegular flex items-center justify-center gap-0 pc-1:gap-2 overflow-hidden whitespace-nowrap'>{t('complaint-content8')} <span className='hidden pc-1:block'><FaArrowRight /></span></a>
                        </div>
                        <span className='font-customExtraBold'>
                        {t('complaint-content9')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content10')}</div>
                        <span className='font-customExtraBold'>
                        {t('complaint-content11')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content12')}</div>
                        <span className='font-customExtraBold'>
                        {t('complaint-content13')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content14')}
                            <br/>
                            {t('complaint-content15')}
                            <br/>
                            {t('complaint-content16')}
                            <br/>
                            {t('complaint-content17')}
                        </div>
                        <br/>
                        <span className='font-customExtraBold text-[21px] leading-tight'>
                        {t('complaint-content18')}
                        </span>
                        <br/>
                        <span className='font-customExtraBold'>
                        {t('complaint-content19')}
                        </span>
                        <div className='w-[100%] h-fit py-2'>
                            <div className='w-[100%] h-fit flex items-center gap-1'><span className='text-[21px]'><BsDot /></span> {t('complaint-content20')}</div>
                            <div className='w-[100%] h-fit flex items-center gap-1'><span className='text-[21px]'><BsDot /></span> {t('complaint-content21')}</div>
                            <div className='w-[100%] h-fit flex items-center gap-1'><span className='text-[21px]'><BsDot /></span> {t('complaint-content22')}</div>
                            <div className='w-[100%] h-fit flex items-center gap-1'><span className='text-[21px]'><BsDot /></span> {t('complaint-content23')}</div>
                        </div>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content24')}</div>
                        <div className='w-[100%] h-fit py-2'>{t('complaint-content25')}</div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}