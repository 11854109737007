import React, { useState } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import { TbSquareRoundedChevronDown } from 'react-icons/tb'
// import Dropdown from '../../components/dropdown/Dropdown'
import { useTranslation } from 'react-i18next'
import apk from '../../assets/documents/KTAKilat.apk'
// import Pdf from '../../../assets/documents/riplay_2024.pdf'
import Pdf from '../../assets/documents/riplay_2024.pdf'
import { IoLogoWhatsapp } from "react-icons/io5";
import { AiOutlineArrowUp } from 'react-icons/ai'

export default ()=>{
    const {t,i18n} = useTranslation()
    const [expand, setExpand] = useState(false)
    const expanding = () => setExpand(!expand)
    const [show, setShow] = useState(false)
    return(
        <>
        <div className='hidden sm:block justify-center w-[100%] bg-colorBlueDark px-5 lg:px-10 xl:px-14 py-10'>
            <div>
                <div className='w-[100%] flex justify-center'>
                    <div className='flex h-fit w-[100%] pc-3:w-[1366px]'>
                        <div className='w-[30%] pc-3:w-[45%] h-[450px] pl-10'>
                            <img src={require('../../assets/images/pendanaan-white-logo.png')} alt='pendanaan-1' className='w-[225px] object-cover mb-10'/>

                            <div className='flex w-[100%] lg:w-[75%] items-start gap-3 mt-5'>
                                <span className='mt-[5px] text-[14px] text-white font-customRegular'><FaMapMarkerAlt/></span>
                                <span className='text-[14px] text-white font-customRegular'>Foresta Business Loft 3 No. 30 & 31, Jl. BSD Raya Utama, Kec. Pagedangan, Tangerang, Banten 15331.</span>
                            </div>

                            <div className='flex items-start gap-3 mt-3'>
                                <span className='mt-[5px] text-[14px] text-white font-customRegular'><GrMail/></span>
                                <a href='mailto:customer@pendanaan.com' className='text-[14px] underline text-white font-customRegular'>customer@pendanaan.com</a>
                            </div>

                            <div className='flex items-start gap-3 mt-3 text-white'>
                                <span className='mt-[5px] text-[14px] font-customRegular'><FaPhoneAlt/></span>
                                <ul>
                                    <li>
                                        <div className='flex item-center'>
                                        &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150201919' className='text-[14px] font-customRegular underline'>021 - 5020 1919</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='flex item-center'>
                                        &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150201809' className='text-[14px] font-customRegular underline'>021 - 5020 1809</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='flex item-center'>
                                        &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150202889' className='text-[14px] font-customRegular underline'>021 - 5020 2889</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className='flex items-start gap-3 mt-3 text-white'>
                                <span className='mt-[5px] text-[14px] font-customRegular'><IoLogoWhatsapp /></span>
                                <a href="https://api.whatsapp.com/send/?phone=628119990518 " data-v-9ba4bce6="" className='text-[14px] underline text-white font-customRegular' target='_blank'>0811 - 9990 518</a>
                            </div>

                            <div className='mt-5'>
                                <iframe className='rounded-xl' width="250" height="150" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=350&amp;height=200&amp;hl=en&amp;q=KTA%20Kilat()&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
                            </div>
                        </div>

                        <div className='grid w-[35%] pc-3:w-[45%] h-[450px] ml-5 pc-3:ml-0'>
                            <div className='grid gap-5 text-[14px]'>
                                <CustomLink to = '/' className='w-fit text-white font-customRegular underline py-2'>{t('home.navbar')}</CustomLink>
                                <CustomLink to = '/about-us' className='w-fit text-white font-customRegular underline py-2'>{t('about.navbar')}</CustomLink>
                                <CustomLink to = '/news' className='w-fit text-white font-customRegular underline py-2'>{t('news.navbar')}</CustomLink>
                                <CustomLink to = '/faq' className='w-fit text-white font-customRegular underline py-2'>{t('faq.navbar')}</CustomLink>
                                <CustomLink to = '/life-at-pendanaan' className='w-fit text-white font-customRegular underline py-2'>{t('lifeatpendanaan.navbar')}</CustomLink>
                                <CustomLink to = '/lender' className='w-fit text-white font-customRegular underline py-2'>Lender</CustomLink>
                            </div>

                            <div className='flex mt-5'>
                                <div className='mr-5'>
                                    <span className='text-[14px] text-white font-customRegular'>{t('follow.ktakilat')}</span>
                                    <div className='flex h-[40px] gap-3 mt-3'>
                                        <a role={'button'} href='https://www.facebook.com/ktakilat.id' className={`w-[40px] bg-[url('./assets/images/facebook-orange.png')] hover:bg-[url('./assets/images/facebook-white.png')] bg-cover`} target='_blank'/>
                                        <a role={'button'} href='https://www.instagram.com/ktakilat.id/' className={`w-[40px] bg-[url('./assets/images/instagram-orange.png')] hover:bg-[url('./assets/images/instagram-white.png')] bg-cover`} target='_blank'/>
                                    </div>
                                </div>

                                <div className='ml-5'>
                                    <span className='text-[14px] text-white font-customRegular'>{t('follow.pendanaan')}</span>
                                    <div className='flex h-[40px] gap-3 mt-3'>
                                        <a role={'button'} href='https://www.linkedin.com/company/pt-pendanaan-teknologi-nusa/mycompany/' className={`w-[40px] bg-[url('./assets/images/linkedin-orange.png')] hover:bg-[url('./assets/images/linkedin-white.png')] bg-cover`} target='_blank'/>
                                        <a role={'button'} href='https://www.instagram.com/lifeatpendanaan/' className={`w-[40px] bg-[url('./assets/images/instagram-orange.png')] hover:bg-[url('./assets/images/instagram-white.png')] bg-cover`} target='_blank'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='w-[30%] pc-3:w-[20%] h-fit pr-10 ml-5 pc-3:ml-0'>
                            <span className='text-[14px] text-white font-customRegular'>{t('footer.afpi')}</span>
                            <div className='w-[100%] h-fit mt-3'>
                                <img src={require('../../assets/images/afpi-white-logo.png')} alt='afpi-1' className='h-[40px] object-cover'/>
                                <img src={require('../../assets/images/ojk-white-logo.png')} alt='ojk-1' className='h-[50px] object-cover mt-5 -ml-1'/>
                            </div>

                            <div className='mt-5'>
                                <span className='text-[14px] text-white font-customRegular'>{t('footer.iso')}</span>
                                <img src={require('../../assets/images/iso.png')} alt='iso-1' className='h-[55px] mt-2 object-cover'/>
                            </div>

                            <div className='mt-5'>
                                <span className='text-[14px] text-white font-customRegular'>{t('footer.laps')}</span>
                                <img src={require('../../assets/images/laps-white.png')} alt='iso-1' className='h-[50px] mt-2 object-cover'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[100%] flex justify-center'>
                    <div className='w-[100%] pc-3:w-[1366px]'>
                        <div className='w-[100%] pc-3:w-[1366px] h-[1px] bg-[#94959b] mt-16 pc-3:mt-5'/>
                        
                        <div className='w-[100%] flex items-center justify-between'>
                            <span className='text-[14px] h-[50px] flex items-center text-white font-customRegular'>2022 © PT Pendanaan Teknologi Nusa</span>
                            <div className='flex gap-3 items-center'>
                                <a
                                    href={Pdf} target='_blank' rel='noreferrer' className='w-fit text-[14px] text-white font-customRegular underline py-2'>
                                        RIPLAY
                                </a>

                                <a href={process.env.PUBLIC_URL+"/kebijakan_privasi.html"} target='_blank' className='w-fit text-[14px] text-white font-customRegular underline py-2'>
                                    {t('privacy-aggrement')}
                                </a>

                                <a href={process.env.PUBLIC_URL+"/syarat_dan_ketentuan.html"} target='_blank' className='w-fit text-[14px] text-white font-customRegular underline py-2'>
                                    {t('term-and-condition')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* Mobile */}
        <div className='block sm:hidden w-[100%] h-fit px-5 py-10 bg-colorBlueDark'>
            <div className='flex h-fit'>
                <img src={require('../../assets/images/pendanaan-white-logo.png')} alt='pendanaan-1' className='w-[200px] object-cover mb-5'/>
            </div>

            <div className='flex w-[100%]'>
                <span className='text-[14px] text-white font-customRegular'>
                    {t('footer.afpi')}
                </span>
            </div>
            <div className='flex w-[100%]'>
                <div className='w-[50%] h-[100%]'>
                    <img src={require('../../assets/images/ojk-white-logo.png')} alt='ojk-1' className='w-[90%] object-cover'/>
                </div>
                <div className='w-[50%] h-[100%]'>
                    <img src={require('../../assets/images/afpi-white-logo.png')} alt='afpi-1' className='w-[90%] object-cover'/>
                </div>
            </div>
            <div className='flex w-[100%] mt-5'>
                <div className='w-[50%] h-[100%]'>
                    <span className='text-[14px] text-white font-customRegular'>
                        {t('footer.iso')}
                    </span>
                    <img src={require('../../assets/images/iso.png')} alt='iso-1' className='h-[52px] mt-1 object-cover'/>
                </div>
                <div className='w-[50%] h-[100%]'>
                    <span className='text-[14px] text-white font-customRegular'>
                        {t('footer.laps')}
                    </span>
                    <img src={require('../../assets/images/laps-white.png')} alt='iso-1' className='w-[70%] mt-1 object-cover'/>
                </div>
            </div>            

            <div className='grid mt-10 gap-5 text-[14px]'>
                <CustomLink to = '/' className='w-fit text-white font-customRegular underline py-2'>{t('home.navbar')}</CustomLink>
                <CustomLink to = '/about-us' className='w-fit text-white font-customRegular underline py-2'>{t('about.navbar')}</CustomLink>
                <CustomLink to = '/news' className='w-fit text-white font-customRegular underline py-2'>{t('news.navbar')}</CustomLink>
                <CustomLink to = '/faq' className='w-fit text-white font-customRegular underline py-2'>{t('faq.navbar')}</CustomLink>
                <CustomLink to = '/life-at-pendanaan' className='w-fit text-white font-customRegular underline py-2'>{t('lifeatpendanaan.navbar')}</CustomLink>
                <CustomLink to = '/lender' className='w-fit text-white font-customRegular underline py-2'>Lender</CustomLink>
            </div>

            <div className='flex justify-between mt-10'>
                <div>
                    <span className='text-[14px] text-white font-customRegular'>{t('follow.ktakilat')}</span>
                    <div className='flex h-[40px] gap-3 mt-3'>
                        <a role={'button'} href='https://www.facebook.com/ktakilat.id' className={`w-[40px] bg-[url('./assets/images/facebook-orange.png')] hover:bg-[url('./assets/images/facebook-white.png')] bg-cover`} target='_blank'/>
                        <a role={'button'} href='https://www.instagram.com/ktakilat.id/' className={`w-[40px] bg-[url('./assets/images/instagram-orange.png')] hover:bg-[url('./assets/images/instagram-white.png')] bg-cover`} target='_blank'/>
                    </div>
                </div>

                <div>
                    <span className='text-[14px] text-white font-customRegular'>{t('follow.pendanaan')}</span>
                    <div className='flex h-[40px] gap-3 mt-3'>
                        <a role={'button'} href='https://www.linkedin.com/company/pt-pendanaan-teknologi-nusa/mycompany/' className={`w-[40px] bg-[url('./assets/images/linkedin-orange.png')] hover:bg-[url('./assets/images/linkedin-white.png')] bg-cover`} target='_blank'/>
                        <a role={'button'} href='https://www.instagram.com/lifeatpendanaan/' className={`w-[40px] bg-[url('./assets/images/instagram-orange.png')] hover:bg-[url('./assets/images/instagram-white.png')] bg-cover`} target='_blank'/>
                    </div>
                </div>
            </div>

            <div className='w-[100%] h-fit'>
                <div className='flex w-[100%] lg:w-[75%] items-start gap-3 mt-5'>
                    <span className='mt-[5px] text-[14px] text-white font-customRegular'><FaMapMarkerAlt/></span>
                    <span className='text-[14px] text-white font-customRegular'>Foresta Business Loft 3 No. 30 & 31, Jl. BSD Raya Utama, Kec. Pagedangan, Tangerang, Banten 15331.</span>
                </div>

                {/* <div className='flex items-start gap-3 mt-3'>
                    <span className='mt-[5px] text-[14px] text-white font-customRegular'><GrMail/></span>
                    <span className='text-[14px] text-white font-customRegular'>customer@pendanaan.com</span>
                </div> */}

                {/* <div className='flex items-start gap-3 mt-3'>
                    <span className='mt-[5px] text-[14px] text-white font-customRegular'><FaPhoneAlt/></span>
                    <span className='text-[14px] text-white font-customRegular'>021 - 5020 1919</span>
                </div> */}
                <div className='flex items-start gap-3 mt-3'>
                    <span className='mt-[5px] text-[14px] text-white font-customRegular'><GrMail/></span>
                    <a href='mailto:customer@pendanaan.com' className='text-[14px] underline text-white font-customRegular'>customer@pendanaan.com</a>
                </div>
                
                <div className='flex items-start gap-3 mt-3 text-white'>
                    <span className='mt-[5px] text-[14px] font-customRegular'><FaPhoneAlt/></span>
                    <ul>
                        <li>
                            <div className='flex item-center'>
                            &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150201919' className='text-[14px] font-customRegular underline'>021 - 5020 1919</a>
                            </div>
                        </li>
                        <li>
                            <div className='flex item-center'>
                            &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150201809' className='text-[14px] font-customRegular underline'>021 - 5020 1809</a>
                            </div>
                        </li>
                        <li>
                            <div className='flex item-center'>
                            &#9679;&nbsp;&nbsp;&nbsp;<a href='tel:+622150202889' className='text-[14px] font-customRegular underline'>021 - 5020 2889</a>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='flex items-start gap-3 mt-3 text-white'>
                    <span className='mt-[5px] text-[14px] font-customRegular'><IoLogoWhatsapp /></span>
                    <a href="https://api.whatsapp.com/send/?phone=628119990518 " data-v-9ba4bce6="" className='text-[14px] underline text-white font-customRegular' target='_blank'>0811 - 9990 518</a>
                </div>

                <div className='mt-5'>
                    <iframe className='rounded-xl' width="100%" height="200px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=350&amp;height=200&amp;hl=en&amp;q=KTA%20Kilat()&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
                </div>
            </div>

            <div className='my-5 w-[100%] h-[1px] bg-[#94959b] items-center justify-between'/>
            <div className='grid'>
                <span className='text-[14px] text-white font-customRegular'>2022 © PT Pendanaan Teknologi Nusa</span>
                <div className='flex items-center gap-3'>
                    <a href={Pdf} target='_blank' rel='noreferrer' className='w-fit text-[12px] text-white font-customRegular underline py-2'>
                    RIPLAY
                    </a>

                    <a href={process.env.PUBLIC_URL+"/kebijakan_privasi.html"} target='_blank' className='w-fit text-[12px] text-white font-customRegular underline py-2'>
                        {t('privacy-aggrement')}
                    </a>

                    <a href={process.env.PUBLIC_URL+"/syarat_dan_ketentuan.html"} target='_blank' className='w-fit text-[12px] text-white font-customRegular underline py-2'>
                        {t('term-and-condition')}
                    </a>
                </div>
            </div>
        </div>

        <div className='flex justify-center px-5 lg:px-14 w-[100%] h-fit bg-colorOrangeDark'>
            {/* <div className='absolute flex items-center justify-center w-[25px] h-[25px] rounded-xl -top-3 right-4 shadow-sm pc-3:right-[275px] bg-white'>
                <span className='text-colorOrangeDark'>
                    <AiOutlineArrowUp/>
                </span>
            </div> */}
            <div className='w-[100%] pc-3:w-[1366px] pt-10 pb-5'>

            <div className='w-[100%] pb-10'>
                <div className='flex items-center justify-between cursor-pointer' onClick={expanding}>
                    <h2 className='text-[21px] text-white font-customBold'>{t('disclaimer')}</h2>
                    <span className={`text-[26px] text-white font-customBold font-bold ${ expand ? 'rotate-180' : 'rotate-0' } duration-500`}><TbSquareRoundedChevronDown/></span>
                </div>

                <div className={`w-[100%] ${ expand ? 'h-fit' : 'h-0 overflow-hidden' } transition-all duration-500`}>
                    <div className='flex gap-3 items-start mt-3'>
                        <p className={`text-[14px] font-customRegular text-white`}>1.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.1')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>2.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.2')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>3.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.3')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>4.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.4')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>5.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.5')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>6.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.6')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>7.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.7')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>8.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.8')}</p>
                    </div>

                    <div className='flex gap-3 items-start mt-1'>
                        <p className={`text-[14px] font-customRegular text-white`}>9.</p>
                        <p className={`text-[14px] font-customRegular text-white`}>{t('disclaimer.9')}</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {`${isActive ? 'active' : ''} list-none`}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}